import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  MenuIcon,
  XIcon,
  HomeIcon,
  InformationCircleIcon,
  CogIcon,
  PhoneIcon,
  ChatAlt2Icon,
} from "@heroicons/react/outline";

// import logo from "../../assets/mutualDivorce/image 419.png";s
import { Link, useLocation } from "react-router-dom";
import FreeConsultation from "../freeConsultation/FreeConsultation";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  console.log(location);

  return (
    <div>
      <header className="absolute w-full">
        <nav className="navbar navbar-expand-lg center-nav transparent navbar-light">
          <div className="container flex flex-wrap items-center justify-between">
            {!isOpen && (
              <div className="navbar-brand">
                <Link to="/">
                  <img
                    src={"https://law-wheel.b-cdn.net/image/image%20419.png"}
                    alt="Logo"
                    className="w-20 h-20 xs:w-16 h-16"
                  />
                </Link>
              </div>
            )}
            <div className="flex items-center flex-grow">
              <div className="collapse navbar-collapse flex justify-center w-full hidden lg:flex">
                <ul className="navbar-nav flex space-x-4">
                  <li className="nav-item">
                    <Link
                      className={`nav-link flex items-center font-bold tracking-[-0.01rem] font-space-grotesk ${
                       location.pathname === "/contact" ||
                        location.pathname === "/privacy-policy" ||
                        location.pathname === "/terms-conditions" ||
                        location.pathname === "/refund-policy" ||
                        location.pathname === "/cookies-policy" ||
                        location.pathname === "/data-protection" ||
                        location.pathname === "/disclaimer"
                          ? "text-white"
                          : "text-[#747ed1] hover:text-[#605dba]"
                      }`}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link flex items-center font-bold tracking-[-0.01rem] font-space-grotesk ${
                       location.pathname === "/contact" ||
                        location.pathname === "/privacy-policy" ||
                        location.pathname === "/terms-conditions" ||
                        location.pathname === "/refund-policy" ||
                        location.pathname === "/cookies-policy" ||
                        location.pathname === "/data-protection" ||
                        location.pathname === "/disclaimer"
                          ? "text-white"
                          : "text-[#747ed1] hover:text-[#605dba]"
                      }`}
                      to="/about"
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link flex items-center font-bold tracking-[-0.01rem] font-space-grotesk ${
                         location.pathname === "/contact" ||
                        location.pathname === "/privacy-policy" ||
                        location.pathname === "/terms-conditions" ||
                        location.pathname === "/refund-policy" ||
                        location.pathname === "/cookies-policy" ||
                        location.pathname === "/data-protection" ||
                        location.pathname === "/disclaimer"
                          ? "text-white"
                          : "text-[#747ed1] hover:text-[#605dba]"
                      }`}
                      to="/process"
                    >
                      Process
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link flex items-center font-bold tracking-[-0.01rem] font-space-grotesk ${
                        location.pathname === "/contact" ||
                        location.pathname === "/privacy-policy" ||
                        location.pathname === "/terms-conditions" ||
                        location.pathname === "/refund-policy" ||
                        location.pathname === "/cookies-policy" ||
                        location.pathname === "/data-protection" ||
                        location.pathname === "/disclaimer"
                          ? "text-white"
                          : "text-[#747ed1] hover:text-[#605dba]"
                      }`}
                      to="/contact"
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link flex items-center font-bold tracking-[-0.01rem] font-space-grotesk ${
                        location.pathname === "/contact" ||
                        location.pathname === "/privacy-policy" ||
                        location.pathname === "/terms-conditions" ||
                        location.pathname === "/refund-policy" ||
                        location.pathname === "/cookies-policy" ||
                        location.pathname === "/data-protection" ||
                        location.pathname === "/disclaimer"
                          ? "text-white"
                          : "text-[#747ed1] hover:text-[#605dba]"
                      }`}
                      to="/mutual-form"
                    >
                      Mutual Divorce Form
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar-other flex ml-auto">
              <ul className="navbar-nav flex-row items-center ml-auto">
                <li className="nav-item hidden xl:block lg:block md:block">
                  <button
                    className="btn btn-primary gradient-button text-white !bg-[#747ed1] border-[#747ed1] hover:text-white hover:bg-[#747ed1] hover:border-[#747ed1] focus:shadow-[rgba(92,140,229,1)] active:text-white active:bg-[#747ed1] active:border-[#747ed1] disabled:text-white disabled:bg-[#747ed1] disabled:border-[#747ed1] !rounded-[50rem] font-space-grotesk"
                    onClick={() => setShowModal(!showModal)}
                  >
                    Free Consultation
                  </button>
                </li>

                <div className="md:hidden flex items-center xl:hidden xs:block">
                  <button
                    onClick={toggleSidebar}
                    className="text-slate-800 focus:outline-none"
                  >
                    {isOpen ? (
                      <XIcon className="h-6 w-6 text-slate-600" />
                    ) : (
                      <MenuIcon className="h-6 w-6 text-slate-600" />
                    )}
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </nav>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, x: "-100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ duration: 0.3 }}
              className="fixed top-0 left-0 w-3/4 h-full z-40 flex flex-col p-4 text-white"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "#1E3A8A",
              }}
            >
              <button onClick={toggleSidebar} className="self-end mb-4">
                <XIcon className="h-6 w-6 text-white" />
              </button>
              <div className="mb-8">
                <img
                  src={"https://law-wheel.b-cdn.net/image/image%20419.png"}
                  alt="Description"
                  className="w-20 h-20 object-cover mx-auto"
                />
              </div>
              <div className="flex flex-col gap-6">
                <Link
                  to="/"
                  className="text-lg flex items-center text-white font-space-grotesk"
                >
                  <HomeIcon className="h-5 w-5 mr-2" />
                  Home
                </Link>
                <Link
                  to="/about"
                  className="text-lg flex items-center text-white font-space-grotesk"
                >
                  <InformationCircleIcon className="h-5 w-5 mr-2" />
                  About
                </Link>
                <Link
                  to="/process"
                  className="text-lg flex items-center text-white font-space-grotesk"
                >
                  <CogIcon className="h-5 w-5 mr-2" />
                  Process
                </Link>
                <Link
                  to="/contact"
                  className="text-lg flex items-center text-white font-space-grotesk"
                >
                  <PhoneIcon className="h-5 w-5 mr-2" />
                  Contact
                </Link>
                <Link
                  to="/mutual-form"
                  className="text-lg flex items-center text-white font-space-grotesk"
                >
                  <PhoneIcon className="h-5 w-5 mr-2" />
                  Mutual Divorce Form
                </Link>
                <button
                  className="text-lg flex items-center text-white font-space-grotesk"
                  onClick={() => {
                    setShowModal(true);
                    toggleSidebar();
                  }}
                >
                  <ChatAlt2Icon className="h-5 w-5 mr-2" />
                  Free Consultation
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>
      <FreeConsultation showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Navbar;
