import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Toaster } from 'react-hot-toast';
// import Contact from "./components/contact/Contact";
// import Home from "./components/section/Home";
// import Process from "./components/service/Process";
// import Mutual from "./components/mutualDivorce/Mutual";
// import About from "./components/about/About";
// import PrivacyPolicy from "./components/policies/PrivacyPolicy";
// import TermsConditions from "./components/policies/TermsConditions";
// import RefundPolicy from "./components/policies/RefundPolicy";
// import CookiesPolicy from "./components/policies/CookiesPolicy";
// import DataProtection from "./components/policies/DataProtection";
// import Disclaimer from "./components/policies/Disclaimer";
import HeroLoader from "./components/layout/HeroLoader";
import "./App.css";
import PropertyDispute from "./components/propertyMatter/PropertyDispute";

const Home = lazy(() => import("./components/section/Home"));
const About = lazy(() => import("./components/about/About"));
const Mutual = lazy(() => import("./components/mutualDivorce/Mutual"));
const Contact=lazy(() => import("./components/contact/Contact"));
const Process=lazy(() => import("./components/service/Process"));
const TermsConditions = lazy(() => import('./components/policies/TermsConditions'));
const RefundPolicy = lazy(() => import('./components/policies/RefundPolicy'));
const CookiesPolicy = lazy(() => import('./components/policies/CookiesPolicy'));
const DataProtection =lazy(() => import("./components/policies/DataProtection"));
const Disclaimer =lazy(() => import("./components/policies/Disclaimer"));
const PrivacyPolicy=lazy(() => import("./components/policies/PrivacyPolicy"));

function App() {
  return (
    <>
      <Router>
      <Suspense fallback={<HeroLoader />}>
        <Routes>
          <Route  path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/process" element={<Process />} />
          <Route path="/mutual-form" element={<Mutual  />} />
          <Route path="/about" element={<About  />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy  />} />
          <Route path="/terms-conditions" element={<TermsConditions  />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/data-protection" element={<DataProtection />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/property-dispute" element={<PropertyDispute />} />
        </Routes>
        <Toaster position="bottom-center" />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
